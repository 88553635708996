import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import AppBar from '../components/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import './layout.css';

const useStyles = makeStyles((theme) => ({
  main: {
    scrollBehavior: 'smooth',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
    },
    paddingTop: theme.spacing(8),
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar />
        <main className={classes.main}>{children}</main>
      </ThemeProvider>
    </>
  );
};

export const HeroLayout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        <AppBar autoHiding lightBackgroundVersion={false} showButtons />
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
