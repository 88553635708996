import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link as GatsbyLink } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import useThrottledOnScroll from '../useThrottledOnScroll';

import logoWhite from '../images/sugar-stack-logo-with-tagline-white.svg';
import logo from '../images/sugar-stack-logo-with-tagline-white-blue.svg';

const useStyles = makeStyles((theme) => ({
  topBar: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 500,
    position: 'fixed',
    transition: 'all 0.3s',
    opacity: 1,
  },
  topBarHidden: {
    top: -100,
    left: 0,
    right: 0,
    zIndex: 500,
    position: 'fixed',
    transition: 'all 0.3s',
    opacity: 0,
  },
  container: {
    backgroundColor: '#039BE5',
    '& .MuiButton-text': {
      padding: 2,
      minWidth: 0,
      marginLeft: '10px',
      [theme.breakpoints.up('lg')]: {
        marginLeft: '22px', // +2px padding = 32px spacing
        '& img': {
          display: 'none',
        },
      },
    },
    '& .MuiButton-contained': {
      padding: '6px 21px',
      // borderRadius: "28px",
      marginLeft: '24px',
      fontSize: '1rem',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 64,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    display: 'flex',
    // alignItems: "center",
    justifyContent: 'space-between',
    // maxWidth: 960,
    width: '100%',
    // margin: "0 auto",
  },
  logoContainer: {
    // prevent the flex item from becoming higher than its content logo image
    [theme.breakpoints.down('sm')]: {
      // height: 34,
    },
    lineHeight: 0,
    // height: 38,
  },
  logo: {
    marginTop: 2,
    [theme.breakpoints.down('sm')]: {
      // marginTop: 4,
      height: 40,
    },
    height: 40,
  },
}));

const AppBar = ({
  lightBackgroundVersion = true,
  autoHiding = false,
  threshold = 240,
  showButtons = false,
}) => {
  const classes = useStyles();

  const [isShowing, setIsShowing] = useState(autoHiding ? false : true);
  // const [elevation, setElevation] = useState(autoHiding ? 0 : 5)

  useThrottledOnScroll(
    autoHiding
      ? (e) => {
          // console.log("onScroll", window.scrollY)
          if (window.scrollY > 300) {
            setIsShowing(true);
          } else {
            setIsShowing(false);
          }
          // if (window.scrollY > 500) {
          //   setElevation(5)
          // } else {
          //   setElevation(0)
          // }
        }
      : null,
    166,
  );

  // console.log("render")
  return (
    <div
      className={isShowing ? classes.topBar : classes.topBarHidden}
      // style={{ top: isShowing ? undefined : -100 }}
      id="appbar"
    >
      <Paper elevation={lightBackgroundVersion ? 1 : 5}>
        <div
          className={classes.container}
          style={{
            backgroundColor: lightBackgroundVersion ? '#333' : '#039BE5',
          }}
        >
          <GatsbyLink to="/" className={classes.logoContainer}>
            <img
              src={lightBackgroundVersion ? logo : logoWhite}
              alt=""
              className={classes.logo}
            />
          </GatsbyLink>
          {showButtons ? (
            <GatsbyLink
              to="/blog/20201028/introducing-the-sjgar-stack"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained">
                <span style={{ textDecoration: 'none' }}>
                  Read the introduction post
                </span>
              </Button>
            </GatsbyLink>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default AppBar;
