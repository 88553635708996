/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  const cookieHubConfig =
    process.env.NODE_ENV !== 'production'
      ? {
          type: 'text/javascript',
          innerHTML: `
    var cpm = {};
    (function(h,u,b){
    var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
    e.async=true;e.src='https://cookiehub.net/dev/c2/06163f24.js';
    e.onload=function(){u.cookiehub.load(b);}
    d.parentNode.insertBefore(e,d);
    })(document,window,cpm);        `,
        }
      : {
          type: 'text/javascript',
          innerHTML: `
    var cpm = {};
    (function(h,u,b){
    var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
    e.async=true;e.src='https://cookiehub.net/c2/06163f24.js';
    e.onload=function(){u.cookiehub.load(b);}
    d.parentNode.insertBefore(e,d);
    })(document,window,cpm);       `,
        };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={[cookieHubConfig]}
      meta={[
        {
          name: `viewport`,
          content: 'width=device-width',
        },

        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: image,
        },
        {
          name: 'google-site-verification',
          content: 'OC9owhy43jKdPiniY9B5h5KEdEkTW3RGGptldAl42O4',
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
