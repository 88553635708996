import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(5),
      marginLeft: theme.spacing(0),
    },
    padding: theme.spacing(8),
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
  },
}));

const MainBox = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} {...props}>
      {children}
    </Box>
  );
};

export default MainBox;
