const colors = {
  primary: '#039BE5',
  secondary: '#fff',
  tertiary: '#5b9853',
  divider: '#d8d9e0',
  background: '#f7f8fa',
  background2: '#f3f4f6',
  text: '#202020',
  text1: '#EE7F00',
  text2: '#343c5a',
  text3: '#9496aa',
  text4: '#afb1c0',
  hoverOpacity: 0.8,
};

export default colors;
