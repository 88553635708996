import React from "react"
import throttle from "lodash.throttle"

const noop = () => {}

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  )

  React.useEffect(() => {
    if (throttledCallback === noop) {
      return undefined
    }

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

export default useThrottledOnScroll
