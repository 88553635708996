import { createMuiTheme, colors as muiColors } from '@material-ui/core';

import colors from './colors';

const headingFontFamily = 'Roboto';

// A custom theme for this app
let theme = createMuiTheme({
  typography: {
    body: {
      lineHeight: 1.5,
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      // marginBottom: "1.34375rem",
      // // color: colors.primary,
      // lineHeight: 1.05714,
      fontFamily: 'Raleway',
      textTransform: 'uppercase',
      color: '#303030',
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 600,
      // fontFamily: 'Raleway',
      // marginBottom: "1.28125rem",
      // // color: colors.primary,
      lineHeight: '2.24rem',
      // fontFamily: headingFontFamily,
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 600,
      // marginBottom: "1.25rem",
      // // color: colors.primary,
      // lineHeight: 1.25,
      fontFamily: headingFontFamily,
    },
    h4: {
      // fontSize: "1rem",
      // fontWeight: 700,
      // marginBottom: "1.375rem",
      // lineHeight: 1,
      fontFamily: headingFontFamily,
    },
    h5: {
      // fontSize: "1rem",
      // fontWeight: 700,
      // marginBottom: "1.375rem",
      // lineHeight: 1,
      fontFamily: headingFontFamily,
    },
    h6: {
      // fontSize: "1rem",
      // fontWeight: 700,
      // marginBottom: "1.375rem",
      // lineHeight: 1,
      fontFamily: headingFontFamily,
    },
    body1: {
      // fontSize: "1rem",
      lineHeight: '32px',
    },
    body2: {
      // fontSize: "1rem",
      // lineHeight: 1.58,
      lineHeight: '32px',
    },
    fontSize: 20,
    fontFamily: [
      // 'source-serif-pro',
      // 'Georgia',
      // 'Cambria',
      // 'Times New Roman',
      // 'Times',
      // 'serif',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: muiColors.red.A400,
    },
    background: {
      default: `#fff`,
    },
    textSecondary: {
      default: `#fff`,
    },
    text: {
      primary: colors.text,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // '@font-face': fontFaceNNDagny,
        html: {
          // WebkitFontSmoothing: 'auto',
        },
        a: {
          color: '#466DB2',
          textDecoration: 'none',
        },
        'a:hover': {
          // color: "#33776D",
          // fontWeight: 500,
          textDecoration: 'underline',
        },
      },
    },
    MuiButton: {
      root: {
        // textTransform: "none",
        fontWeight: 700,
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 8,
        paddingBottom: 8,

        '&:hover': {
          color: '#039be5',
        },
      },
      contained: {
        // boxShadow: "none",
        // backgroundColor: "#F07543",
        backgroundColor: 'white',
        color: '#039be5',
        // backgroundColor: "#0079BC",
        //   color: "white",
        '&:hover': {
          // boxShadow: "none",
          backgroundColor: '#006db3',
          color: 'white',
        },
      },
      containedPrimary: {
        color: '#fff',

        '&:hover': {
          backgroundColor: colors.secondary,
          color: '#fff',
        },
      },
      outlinedPrimary: {
        color: colors.primary,
        border: `1px solid ${colors.primary}`,

        '&:hover': {
          backgroundColor: '#fff7ee',
        },
      },
      outlinedSecondary: {
        color: '#ddd',
        border: `1px solid #aaa`,
        textTransform: 'lowercase',
        fontWeight: 'normal',
        '&:hover': {
          backgroundColor: '#fff',
          color: 'black',
        },
      },
      outlinedSizeSmall: {
        fontSize: 12,
      },
    },
    MuiExpansionPanel: {
      root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
          backgroundColor: '#f1edeb',
          borderTop: `2px solid ${colors.primary}`,
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        order: -1,
        marginLeft: 0,
        marginRight: 12,
        padding: 0,
        color: colors.primary,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.primary,
        '&:hover': {
          backgroundColor: 'inherit',
        },
        padding: 8,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '8px 12px 8px',
      },
      adornedEnd: {
        paddingRight: '0px',
      },
    },
    MuiSelect: {
      icon: {
        color: colors.primary,
        borderLeft: `1px solid #ccc`,
      },
      iconOpen: {
        borderRight: `1px solid #ddd`,
        borderLeft: 'inherit',
      },
    },
    //   label: {
    //     marginTop: 0,
    //   },
    //   contained: {
    //     borderRadius: '4px',
    //     padding: '8px 48px',
    //     fontSize: '1.125rem',
    //     boxShadow: '0 0 0 0',
    //     '&$disabled': {
    //       backgroundColor: '#CEDBE7',
    //       color: '#6E7F90',
    //     },
    //     '&:hover': {
    //       boxShadow: 0,
    //     },
    //     '&:active': {
    //       boxShadow: 0,
    //     },
    //   },
    //   disabled: {},
    //   outlinedPrimary: {
    //     border: '2px solid #FD008B',
    //     borderRadius: '4px',
    //     fontSize: '1.125rem',
    //     fontWeight: 500,
    //     '&:hover': {
    //       border: '2px solid #FD008B',
    //     },
    //   },
    // },
    // MuiCardContent: {
    //   root: {
    //     padding: '24px 39px',
    //   },
    // },
    MuiListItem: {
      root: {
        '&:before': { backgroundColor: 'red', display: 'none' },
      },
    },
    // MuiCardActions: {
    //   root: {
    //     padding: '10px 27px',
    //     borderTop: '1px solid #c4d8eb',
    //   },
    // },
    MuiInputBase: {
      root: {
        // backgroundColor: '#ffffff',
      },
      input: {
        padding: '16px 8px 16px',
        caretColor: colors.primary,
        color: colors.text2,
        '&::placeholder': {
          // color: '#A2B8CE',
          opacity: 1,
          lineHeight: 'normal',
        },
        '&::-ms-input-placeholder': {
          // color: '#A2B8CE',
          opacity: 1,
        },
      },
    },
    // MuiInputLabel: {
    //   root: {
    //     color: '#6E7F90',
    //   },
    // },
    // MuiInput: {
    //   underline: {
    //     '&:before': { borderBottom: '1px solid #6E7F90' },
    //     '&:after': { borderBottom: `1px solid ${colors.primary}` },
    //     '&:hover:not(.Mui-disabled)::before': {
    //       borderBottom: '1px solid #6E7F90',
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   notchedOutline: {
    //     borderColor: '#4E5E6F',
    //   },
    //   inputMarginDense: {
    //     paddingTop: '12px',
    //     paddingBottom: '12px',
    //   },
    // },
    MuiFormLabel: {
      root: {
        color: colors.text,
        '&$focused': {
          color: colors.text,
        },
      },
    },
    // MuiFormControlLabel: {
    //   root: {
    //     alignItems: 'flex-start',
    //   },
    //   label: {
    //     marginTop: 9,
    //   },
    // },
    // MuiMenuItem: {
    //   root: {
    //     fontWeight: 600,
    //     fontSize: '16px',
    //   },
    // },
    // MuiToggleButton: {
    //   root: {
    //     borderRadius: 24,
    //     height: 48,
    //     boxShadow: '0 2px 6px 0 rgba(0,0,0,0.25)',
    //     '&.Mui-selected': {
    //       boxShadow: 'inherit',
    //     },
    //   },
    //   label: {
    //     textTransform: 'none',
    //     fontSize: '1rem',
    //     paddingLeft: 12,
    //     paddingRight: 12,
    //   },
    // },
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 2px 1px -1px rgba(0,0,0,0.08)',
    '0px 1px 5px 0px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 3px 1px -2px rgba(0,0,0,0.08)',
    '0px 1px 8px 0px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.07),0px 3px 3px -2px rgba(0,0,0,0.08)',
    '0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.08)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.08)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.08)',
    '0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.08)',
    '0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.08)',
    '0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.08)',
    '0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.08)',
    '0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.08)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.08)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.08)',
    '0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.08)',
    '0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.08)',
    '0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.08)',
    '0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.08)',
    '0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.08)',
    '0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.08)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.08)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.08)',
    '0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.08)',
    '0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.08)',
    '0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.08)',
  ],
});
theme = {
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialogTitle: {
      root: {
        color: colors.primary,
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
          padding: '24px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 15px 15px',
        [theme.breakpoints.up('sm')]: {
          padding: '0 20px 20px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          padding: '24px',
        },
      },
    },

    // Tabs component
    MuiTabs: {
      root: {},
      flexContainer: {
        borderBottom: `3px solid #e5ded9`,
      },
    },
    MuiTab: {
      root: {
        position: 'relative',
        borderBottom: `3px solid #e5ded9`,
        marginBottom: '-3px',
        fontSize: '1.20em',
        fontWeight: 700,
        textTransform: 'none',

        '&:hover': {
          color: colors.primary,
          borderBottom: `3px solid #cac7c7`,
        },
      },

      textColorPrimary: {
        color: '#414141',
      },

      selected: {
        borderBottom: '3px solid white',
        '&:hover': {
          borderBottom: `3px solid white`,
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        top: '0px',
      },
    },

    // Stepper
    MuiStepper: {
      root: {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      alternativeLabel: {
        alignItems: 'flex-end',
      },
    },
    MuiStepLabel: {
      root: {
        '&$alternativeLabel': {
          flexDirection: 'column-reverse',
        },
      },
      label: {
        color: '#666',
        fontSize: '0.75rem',
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
          fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        '&$alternativeLabel': {
          marginTop: 0,
          marginBottom: 16,
        },
      },
      alternativeLabel: {
        '& $active': {
          color: colors.primary,
          fontSize: '20px',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            display: 'block',
          },
        },
      },
    },
    MuiStepConnector: {
      active: {
        '& $line': {
          backgroundColor: colors.primary,
        },
      },
      completed: {
        '& $line': {
          backgroundColor: colors.primary,
        },
      },
      line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
      },
      lineHorizontal: {
        borderTopStyle: 'none',
      },
      alternativeLabel: {
        top: 'unset',
        bottom: 4,
        left: 'calc(-50% + 5px)',
        right: 'calc(50% + 5px)',
      },
    },
    MuiStepIcon: {
      root: {},
      text: {
        display: 'none',
      },
      completed: {},
    },

    // List
    MuiListItemIcon: {
      root: {
        minWidth: '30px',
      },
    },
    MuiListItemText: {
      primary: {
        color: colors.text,
        fontWeight: 700,
      },
    },
  },
};

// export default responsiveFontSizes(theme);
export default theme;
